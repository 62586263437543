#csvFileUpLoadBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

#csvFileInput{
  /* give this file input a custom style to look good */
  font-size: 1.1em;
  color:#777474;
  font-weight: bold;
  font-size:16px;
  background: white;
  border-radius: 5px;
  /* box-shadow:3px 3px 5px grey; */
  width: 60% !important;
  outline:none;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#instr{
  margin: 20px;
  /* margin-left: 40px; */
  /* margin-right: 40px; */
  font-size: 0.9em;
  color:#777474;
  font-weight: bold;
}

::-webkit-file-upload-button {
  color: white;
  background-color: #424242;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 0 1px 1px #ccc;
  outline: none;
}

::-webkit-file-upload-button:hover {
  background-color: #424242;
}

#csvFileInput::file-selector-button{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

::-webkit-file-upload-button {
  color: white;
  background-color: #353535;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 0 1px 1px #ccc;
  outline: none;
}

.button-col{
  display: flex;
  flex-direction: column;
}
.download-button:hover{
 background-color: #2780e3;
 cursor: pointer;
}

/* In your CSS file */
.copy-fis-data-button {
  padding: 10px 20px;
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.copy-fis-data-button:hover {
  background-color: #45a049;
}

.category-select-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.category-select-popup {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.category-select-popup select {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
}

.category-select-popup button {
    margin-top: 10px;
    padding: 8px 16px;
}

.file-info {
    margin-top: 10px;
    color: #424242;
    font-size: 0.9em;
}

.file-info div {
    margin: 5px 0;
}